import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Les aboiements" />
    <h3 className='underline-title'>Les aboiements</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Bouledogue<br />
        Avec enthousiasme fougeux du début tu aboyas<br />
      </p>
      <p>
        Roquet<br />
        Avec acharnement opiniâtre du milieu tu clabaudais<br />
      </p>
      <p>
        Toutou<br />
        Avec désillusion funeste de la fin tu glapis<br />
        désormais queue entre les pattes<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
